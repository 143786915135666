<template>
    <div v-if="photoService">
        <h1 class="main-header">{{ title }}</h1>

        <UserManager
            ref="userManager"
            :btn-text-create="btnTextCreate"
            :users="users"
            :total-results="totalResults"
            :is-loading="isLoading"
            :many="canSetMultipleUsers"
            :create-user-group="userGroup"
            :can-create-user="hasPerm('accounts.add_photographer')"
            :can-change-user="hasPerm('accounts.add_photographer')"
            @user-created="userCreated"
            @user-updated="updateUser"
            @user-disabled="updateUser"
            @user-enabled="updateUser"
        />
    </div>
</template>

<script>
import PhotoServiceService from "@/services/photo_service.service"
import UserManager from "@/components/UserManager"
import Constants from "@/constants"

export default {
    name: "PhotoServiceUsersManager",

    components: {UserManager},

    props: {
        userGroup: {
            type: String
        }
    },

    data() {
        return {
            isLoading: false,
            photoService: null,
            users: null,
            totalResults: 0,
        }
    },

    mounted() {

        // TODO: parametrizzare ruolo: photographer, photographer manager, legal representative
        // user is photographer manager
        const userPhotoService = this.$store.getters['login/photoService']

        if (userPhotoService) {
            this.$set(this, 'photoService', userPhotoService)
            this.fetchUsers()
        } else {
            const photoServiceId = this.$route.params['photoServiceId']
            if (photoServiceId) {
                PhotoServiceService.retrievePhotoService(photoServiceId)
                    .then((response) => {
                        this.$set(this, 'photoService', response.data)
                        this.fetchUsers()
                    })
            }
        }
    },

    computed: {
        title() {
            return {
                [Constants.USER_GROUP_PHOTOGRAPHERS]: this.$gettext('Photographers'),
                [Constants.USER_GROUP_PHOTOGRAPHERS_MANAGERS]: this.$gettext('Photographer Managers'),
                [Constants.USER_GROUP_LEGAL_REPRESENTATIVES]: this.$gettext('Legal representative'),
            }[this.userGroup]
        },

        btnTextCreate() {
            return {
                [Constants.USER_GROUP_PHOTOGRAPHERS]: this.$gettext('New photographer'),
                [Constants.USER_GROUP_PHOTOGRAPHERS_MANAGERS]: this.$gettext('New photographer manager'),
                [Constants.USER_GROUP_LEGAL_REPRESENTATIVES]: this.$gettext('New legal representative'),
            }[this.userGroup]
        },

        canSetMultipleUsers() {
            return this.userGroup !== Constants.USER_GROUP_LEGAL_REPRESENTATIVES
        }
    },

    methods: {
        fetchUsers() {
            this.isLoading = true
            PhotoServiceService.getPhotoServiceUsers(this.photoService.id)
                .then(response => {
                    const propName = {
                        [Constants.USER_GROUP_PHOTOGRAPHERS]: "photographers",
                        [Constants.USER_GROUP_PHOTOGRAPHERS_MANAGERS]: "photographer_managers",
                        [Constants.USER_GROUP_LEGAL_REPRESENTATIVES]: "legal_representative",
                    }[this.userGroup]

                    const users = response.data[propName]
                    this.users = Array.isArray(users) ? users: [users]
                  this.totalResults = this.users.length

                    this.isLoading = false
                })
        },

        userCreated(newUser) {
            PhotoServiceService.addUserToPhotoService(this.photoService.id, {
                user_id: newUser.id,
                role: this.userGroup
            })
                .then(() => {
                    this.$refs.userManager.notifyUserCreationSuccessfully(newUser)
                    this.fetchUsers()
                })
        },

        updateUser(user) {
            const index = this.users.findIndex(u => u.id === user.id)
            this.$set(this.users, index, user)
        },
    }
}
</script>
