import client_core from "@/services/clients/client_core";

const UserService = {
    getAvailableLanguages: function() {
        return client_core.get(`/v1/languages/`)
    },

    getTimezones: function() {
        return client_core.get(`/v1/timezones/`)
    },

    createUser: function(userData) {
        return client_core.post(`/dashboard/v1/users/`, userData)
    },

    updateUser: function(userId, userData) {
        return client_core.patch(`/dashboard/v1/users/${userId}/`, userData)
    },

    disableUser: function(userId) {
        return client_core.patch(`/dashboard/v1/users/${userId}/disable/`)
    },

    enableUser: function(userId) {
        return client_core.patch(`/dashboard/v1/users/${userId}/enable/`)
    },
}

export default UserService