var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CCard',[_c('CCardHeader',[_c('div',[_c('span',{directives:[{name:"translate",rawName:"v-translate"}],staticClass:"mr-4"},[_vm._v("Found %{ totalResults } results")])]),(_vm.canAddUser)?_c('div',{staticClass:"card-header-actions"},[(_vm.isCollapsed)?_c('CButton',{attrs:{"color":"primary"},on:{"click":function($event){_vm.isCollapsed = false}}},[_vm._v(" "+_vm._s(_vm.btnTextCreate)+" ")]):[_c('CButton',{attrs:{"variant":"outline","color":"primary"},on:{"click":_vm.collapse}},[_c('translate',[_vm._v("Cancel")])],1),_c('CButton',{staticClass:"ml-1",attrs:{"color":"primary"},on:{"click":function($event){return _vm.$refs.newUserEditor.save()}}},[_c('translate',[_vm._v("Save")])],1)]],2):_vm._e()]),_c('CCardBody',{staticClass:"px-0"},[_c('CCollapse',{attrs:{"show":! _vm.isCollapsed,"duration":400}},[_c('CCardBody',{staticClass:"px"},[_c('UserEditor',{ref:"newUserEditor",attrs:{"group":_vm.createUserGroup,"show-password-fields":true},on:{"user-created":_vm.cbUserCreated}})],1)],1),_c('ViewLoadingMessage',{attrs:{"loading":_vm.isLoading}}),(!_vm.isLoading)?_c('CDataTable',{staticClass:"custom-table custom-table--has-sort custom-table--clean",attrs:{"hover":false,"striped":true,"sorter":"","items":_vm.users,"fields":_vm.fields},on:{"update:sorter-value":function (sorter) { return _vm.onSort(sorter); }},scopedSlots:_vm._u([{key:"no-items-view",fn:function(){return [_c('div',{staticClass:"text-center"},[_c('h5',{directives:[{name:"translate",rawName:"v-translate"}]},[_vm._v("No user selected")])])]},proxy:true},{key:"first_name",fn:function(ref){
var item = ref.item;
return [_c('th',{attrs:{"scope":"row"}},[_vm._v(_vm._s(item.first_name))])]}},{key:"last_name",fn:function(ref){
var item = ref.item;
return [_c('th',{attrs:{"scope":"row"}},[_vm._v(_vm._s(item.last_name))])]}},{key:"is_active",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('CSwitch',{staticClass:"mx-1",attrs:{"color":"success","variant":"3d","size":"sm","disabled":!_vm.canChangeUser,"checked":item.is_active},on:{"update:checked":function (val) { return _vm.toggleUserActivation(item); }}})],1)]}},{key:"actions",fn:function(ref){
var item = ref.item;
return [_c('td',{staticStyle:{"text-align":"right"}},[(Boolean(!item._toggled))?_c('CButton',{directives:[{name:"translate",rawName:"v-translate"}],attrs:{"color":"primary","size":"sm","variant":"outline","disabled":!_vm.canChangeUser},on:{"click":function($event){return _vm.toggleDetails(item)}}},[_vm._v("Edit ")]):_c('CButtonClose',{staticClass:"mr-4",on:{"click":function($event){return _vm.toggleDetails(item)}}})],1)]}},{key:"details",fn:function(ref){
var item = ref.item;
return [_c('CCollapse',{attrs:{"show":Boolean(item._toggled),"duration":_vm.collapseDuration}},[_c('CCardBody',{staticClass:"px-0 user-edit"},[_c('h6',{directives:[{name:"translate",rawName:"v-translate"}],staticClass:"mb-4",staticStyle:{"font-weight":"600"}},[_vm._v("Edit user")]),_c('UserEditor',{ref:("editUserEditor_" + (item.id)),attrs:{"is-editing":true,"user":item,"show-save-button":true,"show-password-fields":true},on:{"user-updated":function($event){return _vm.cbUserUpdated(item, $event)},"cancel-clicked":function($event){return _vm.toggleDetails(item)}}})],1)],1)]}}],null,false,3891240675)}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }