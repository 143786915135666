<template>

  <CForm>
    <CRow>
      <CCol :lg="isEditing ? 9: 12">
        <CRow>
          <CCol sm="4" v-if="! isEditing">
            <CInput
                :label="$gettext('Email')"
                v-model="userData.email"
                type="email"
                autocomplete="off"
                autocapitalize="off"
                autocorrect="off"
            >
              <template slot="append">
                <div class="invalid-feedback" style="display: block" v-if="isFieldValid('email') === false">
                  {{ getFieldErrors('email') }}
                </div>
              </template>
            </CInput>
          </CCol>
          <CCol :sm="isEditing ? 6 : 4">
            <CInput
                :label="$gettext('First name')"
                v-model="userData.first_name"
            >
              <template slot="append">
                <div class="invalid-feedback" style="display: block"
                     v-if="isFieldValid('first_name') === false">
                  {{ getFieldErrors('first_name') }}
                </div>
              </template>
            </CInput>
          </CCol>
          <CCol :sm="isEditing ? 6 : 4">
            <CInput
                :label="$gettext('Last name')"
                v-model="userData.last_name"
            >
              <template #invalid-feedback>
                <div class="invalid-feedback" style="display: block" v-if="isFieldValid('last_name') === false">
                  {{ getFieldErrors('last_name') }}
                </div>
              </template>
            </CInput>
          </CCol>
        </CRow>
        <CRow v-if="showPasswordFields">
          <CCol sm="6">
            <CInput
                label="Password"
                v-model="userData.password"
                type="password"
                autocapitalize="off"
                autocorrect="off"
                autocomplete="new-password"
            >
              <template #prepend-content>
                <CIcon name="cipLock"/>
              </template>

              <template #invalid-feedback>
                <div class="invalid-feedback" style="display: block" v-if="isFieldValid('password') === false">
                  {{ getFieldErrors('password') }}
                </div>
              </template>
            </CInput>
          </CCol>
          <CCol sm="6">
            <CInput
                :label="$gettext('Repeat password')"
                v-model="userData.password_repeat"
                type="password"
                autocapitalize="off"
                autocorrect="off"
            >
              <template #prepend-content>
                <CIcon name="cipLock"/>
              </template>

              <template #invalid-feedback>
                <div class="invalid-feedback" style="display: block"
                     v-if="isFieldValid('password_repeat') === false">
                  {{ getFieldErrors('password_repeat') }}
                </div>
              </template>
            </CInput>
          </CCol>
        </CRow>

      </CCol>
    </CRow>

    <div class="text-right">
      <CButton v-if="showSaveButton" class="mr-2" color="primary" variant="outline" @click="$emit('cancel-clicked')">
        <translate>Cancel</translate>
      </CButton>

      <CButton v-if="showSaveButton" color="primary" @click="save">
        <translate>Save</translate>
      </CButton>
    </div>
  </CForm>

</template>


<script>
import UserService from "@/services/user.service"
import formValidationMixin from "@/mixins/formValidationMixin"


function initialState() {
  return {
    userData: {
      email: '',
      first_name: '',
      last_name: '',
      password: '',
      password_repeat: '',
    },
    isLoading: false
  }
}


export default {
  name: "UserEditor",

  mixins: [formValidationMixin],

  props: {
    // photoServiceId: {
    //     type: Number
    // },

    user: {
      type: Object,
      default() {
        return {
          email: '',
          first_name: '',
          last_name: '',
        }
      }
    },

    isEditing: {
      type: Boolean,
      default() {
        return false
      }
    },

    group: {
      type: String
    },

    showPasswordFields: {
      type: Boolean,
      default() {
        return false
      }
    },

    showSaveButton: {
      type: Boolean,
      default() {
        return false
      }
    }
  },

  data() {
    return initialState()
  },

  mounted() {
    this.initData()
  },

  methods: {
    initData() {
      this.userData.email = this.user.email
      this.userData.first_name = this.user.first_name
      this.userData.last_name = this.user.last_name
    },

    clearData() {
      Object.assign(this.$data, initialState())
      this.initData()
      this.validationErrors = null
    },

    save() {
      const msgMandatoryField = this.$gettext('This field is required.')
      const msgPasswordsDontMatch = this.$gettext("Passwords don't match.")

      this.validationErrors = null
      let preValidations = {}

      if (this.userData.password && this.userData.password_repeat
          && this.userData.password !== this.userData.password_repeat) {
        preValidations['password'] = [msgPasswordsDontMatch]
        this.validationErrors = preValidations
        return false
      }

      if (this.isEditing) {
        /*
        Modifica di un utente
        */

        if (this.userData.password && this.userData.password_repeat === '') {
          preValidations['password_repeat'] = [msgMandatoryField]
          this.validationErrors = preValidations
          return false
        }

        let userData = this.userData
        if (userData.password === '')
          delete userData.password

        this.isLoading = true

        UserService.updateUser(this.user.id, userData)
            .then((response) => {
              this.isLoading = false
              const notifyText = this.$language._('User %{user} changed successfully', {user: this.userData.email})
              this.notifyMessage({text: notifyText, messageType: 'success'})
              this.$emit('user-updated', response.data)

              this.userData.password = ''
              this.userData.password_repeat = ''
            })
            .catch((err) => {
              this.isLoading = false
              this.validationErrors = {...err.response.data, ...preValidations}
              const notifyText = this.$language._('There is some errors: check inserted data')
              this.notifyMessage({
                text: notifyText, // 'Attenzione: errore nel salvataggio',
                messageType: 'warning'
              })
            })
      } else {
        /*
        Creazione di un utente
        */

        if (this.userData.password_repeat === '')
          preValidations['password_repeat'] = [msgMandatoryField]

        let userData = this.getPopulatedValues(this.userData)
        userData.photo_service_id = this.photoServiceId

        if (this.group)
          userData.group = this.group

        this.isLoading = true

        UserService.createUser(userData)
            .then((response) => {
              this.isLoading = false
              this.$emit('user-created', response.data)
              this.clearData()
            })
            .catch((err) => {
              this.isLoading = false
              this.validationErrors = {...err.response.data, ...preValidations}
              const notifyText = this.$language._('There is some errors: check inserted data')
              this.notifyMessage({
                text: notifyText,
                messageType: 'warning'
              })
            })
      }
    },
  }
}
</script>