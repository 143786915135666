<template>
  <div>
    <CCard>
      <CCardHeader>
        <div><span class="mr-4" v-translate>Found %{ totalResults } results</span></div>
        <div class="card-header-actions" v-if="canAddUser">
          <CButton color="primary" v-if="isCollapsed" @click="isCollapsed = false">
            {{ btnTextCreate }}
          </CButton>

          <template v-else>
            <CButton variant="outline" color="primary" @click="collapse">
              <translate>Cancel</translate>
            </CButton>

            <CButton color="primary" class="ml-1" @click="$refs.newUserEditor.save()">
              <translate>Save</translate>
            </CButton>
          </template>
        </div>

      </CCardHeader>
      <CCardBody class="px-0">
        <CCollapse :show="! isCollapsed" :duration="400">
          <CCardBody class="px">
            <UserEditor ref="newUserEditor"
                        :group="createUserGroup"
                        :show-password-fields="true"
                        @user-created="cbUserCreated"/>
          </CCardBody>
        </CCollapse>

        <ViewLoadingMessage :loading="isLoading"/>
        <CDataTable
            v-if="!isLoading"
            :hover="false"
            :striped="true"
            sorter
            :items="users"
            :fields="fields"
            @update:sorter-value="(sorter) => onSort(sorter)"
            class="custom-table custom-table--has-sort custom-table--clean"
        >
          <template #no-items-view>
            <div class="text-center">
              <h5 v-translate>No user selected</h5>
            </div>
          </template>
          <template #first_name="{ item }">
            <th scope="row">{{item.first_name}}</th>
          </template>
                <template #last_name="{ item }">
            <th scope="row">{{item.last_name}}</th>
          </template>
          <template #is_active="{ item }">
            <td>
              <CSwitch class="mx-1" color="success" variant="3d" size="sm"
                       :disabled="!canChangeUser"
                       :checked="item.is_active" @update:checked="val => toggleUserActivation(item)"/>
            </td>
          </template>

          <template #actions="{ item }">
            <td style="text-align: right">
              <CButton color="primary" size="sm" variant="outline"
                       v-if="Boolean(!item._toggled)"
                       @click="toggleDetails(item)"
                       :disabled="!canChangeUser" v-translate>Edit
              </CButton>
              <CButtonClose  v-else @click="toggleDetails(item)" class="mr-4"></CButtonClose>
            </td>
          </template>

          <template #details="{ item }">
            <CCollapse :show="Boolean(item._toggled)" :duration="collapseDuration">
              <CCardBody class="px-0 user-edit">
                <h6 class="mb-4" style="font-weight: 600" v-translate>Edit user</h6>
                <UserEditor :ref="`editUserEditor_${item.id}`"
                            :is-editing="true"
                            :user="item"
                            @user-updated="cbUserUpdated(item, $event)"
                            @cancel-clicked="toggleDetails(item)"
                            :show-save-button="true" :show-password-fields="true"/>
              </CCardBody>
            </CCollapse>
          </template>
        </CDataTable>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import UserService from "@/services/user.service"

import ViewLoadingMessage from "@/components/ViewLoadingMessage"
import UserEditor from "@/components/UserEditor"

export default {
  name: "UserManager",

  components: {
    ViewLoadingMessage,
    UserEditor
  },

  props: {
    users: {
      type: Array
    },

    many: {
      type: Boolean,
      default: true
    },

    canCreateUser: {
      type: Boolean,
      default: true
    },

    canChangeUser: {
      type: Boolean,
      default: true
    },

    createUserGroup: {
      type: String
    },

    btnTextCreate: {
      type: String,
      default: 'new'
    },

    isLoading: {
      type: Boolean,
      default: false
    },
    totalResults: {
      type: Number,
      default: 0
    }
  },

  data() {
    return {
      collapseDuration: 0,
      isCollapsed: true,

      fields: [

        {
          key: 'first_name',
          label: this.$gettext('First name'),
        },
        {
          key: 'last_name',
          label: this.$gettext('Last name'),
        },
        {
          key: 'email',
          label: this.$gettext('Email'),
        },
        {
          key: 'is_active',
          label: this.$gettext('Activation status'),
          sorter: {external: true},
        },
        {
          key: 'actions',
          label: '',
          sorter: false,
          filter: false,
        },
      ],
    }
  },

  computed: {

    canAddUser() {
      if (!this.canCreateUser) return false
      return this.many === true || this.many === false && this.users && this.users.length === 0
    }

  },

  methods: {
    onSort(sorter) {
      for(let item of this.users){
         item._toggled = false
      }
    },
    collapse() {
      this.isCollapsed = true
      // this.$refs.newUserEditor.clearData()
    },

    cbUserCreated(user) {
      this.$emit('user-created', user)
      this.collapse()
    },

    cbUserUpdated(item, user) {
      // console.log('cbUserUpdated', a, b)
      this.toggleDetails(item)
      this.$emit('user-updated', user)
      // this.collapse()
    },

    toggleDetails(item) {
     if (!item._toggled) {
        // è stato chiuso il collapse: svuoto il form
        this.$refs[`editUserEditor_${item.id}`].clearData()
     }

        this.$set(item, "_toggled", !item._toggled)


      this.collapseDuration = 300
      this.$nextTick(() => {
        this.collapseDuration = 0
      })
    },

    toggleUserActivation(item) {


      if (item.is_active) {
        UserService.disableUser(item.id)
            .then((response) => {
              const updatedUser = response.data
              this.$emit('user-disabled', updatedUser)
              this.notifyUserDisabled(updatedUser)
            })
      } else {
        UserService.enableUser(item.id)
            .then((response) => {
              const updatedUser = response.data
              this.$emit('user-enabled', updatedUser)
              this.notifyUserEnabled(updatedUser)
            })
      }
    },

    notifyUserCreationSuccessfully(user) {
      const notifyText = this.$language._('User %{user} created successfully', {user: user.email})
      this.notifyMessage({
        text: notifyText,
        messageType: 'success'
      })
    },

    notifyUserDisabled(user) {
      const notifyText = this.$gettextInterpolate(
          this.$gettext('User %{user} has been disabled'),
          {user: user.email}
      )
      this.notifyMessage({text: notifyText, messageType: 'success'})
    },

    notifyUserEnabled(user) {
      const notifyText = this.$gettextInterpolate(
          this.$gettext('User %{user} has been enabled'),
          {user: user.email}
      )
      this.notifyMessage({text: notifyText, messageType: 'success'})
    }
  }
}
</script>
